
















































































































import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {fileStoreActions, fileStoreGetter} from '@/stores/file.store';
import DbFile, {FileEntities, Label} from '@/models/DbFile';
import {Permission} from '@/misc/enums/permission.enum';

const FileStore = namespace('file');

@Component({
  computed: {
    Permission() {
      return Permission;
    },
  },
  components: {
    TableComponent: () => import('@/components/shared/table/Table.component.vue'),
    RJSelect: () => import('@/components/shared/custom-vuetify/RJSelect.vue'),
    SearchBarComponent: () => import('@/components/shared/SearchBar.component.vue'),
  },
})

export default class UploadFileComponent extends Vue {

  @FileStore.Action(fileStoreActions.LOAD_FILES_ACTION)
  private loadFilesAction!: (payload: { entity: FileEntities, value: string }) => Promise<DbFile[]>;

  @FileStore.Action(fileStoreActions.LOAD_LABELS_ACTION)
  private loadLabelsAction!: (customerId: string) => Promise<Label[]>;

  @FileStore.Action(fileStoreActions.DOWNLOAD_FILES_ACTION)
  private downloadFileAction!: (fileId: string) => Promise<Blob>;

  @FileStore.Action(fileStoreActions.DELETE_FILE_ACTION)
  private deleteFileAction!: (id: string) => Promise<void>;

  @FileStore.Action(fileStoreActions.DELETE_LABEL_ACTION)
  private deleteLabelAction!: (id: string) => Promise<void>;

  @FileStore.Action(fileStoreActions.CREATE_FILE_ACTION)
  private createFileAction!: (file: FormData) => Promise<DbFile>;

  @FileStore.Action(fileStoreActions.CREATE_LABEL_ACTION)
  private createLabelAction!: (label: Label) => Promise<Label>;

  @FileStore.Getter(fileStoreGetter.FILES)
  private _files!: DbFile[];

  @FileStore.Getter(fileStoreGetter.LABELS)
  private _labels!: Label[];

  private get files() {
    return this._files;
  }

  private get labels() {
    return this._labels;
  }

  @Prop({required: true})
  public entityId!: string;

  @Prop({required: true})
  public entity!: FileEntities;

  @Prop({default: ''})
  public height!: string;

  private showDeleteDialog: boolean = false;
  private showCreateDialog: boolean = false;
  private selectedFile: { name: string, id?: string } | null = null;
  private selectedLabel: string | Label = '';
  private loading: boolean = false;
  private searchString: string = '';

  private get importantKeys() {
    return {
      name: String,
      label: String,
    };
  }

  private closeDeleteDialog() {
    this.selectedFile = null;
    this.showDeleteDialog = false;
  }

  get tableHeaders(): any[] {
    return [
      // the names are not displayed
      {text: this.$t('GENERAL.NAME').toString(), value: 'name'},
      {text: '', value: 'label'},
      {text: '', value: 'actions', width: '90px', class: 'ml-auto'},
    ];
  }

  public async mounted() {
    this.loading = true;
    await Promise.all([
      this.loadFilesAction({entity: this.entity, value: this.entityId}),
      this.loadLabelsAction(this.$route.params.companyId),
    ]);
    this.loading = false;
  }

  private async download(file: DbFile) {
    saveAs(await this.downloadFileAction(file.id), file.name);
  }

  private onUploadFileIntent() {
    this.showCreateDialog = true;
  }

  private get fileName() {
    return this.selectedFile?.name ?? '';
  }

  private uploadFile(file: File) {
    (this.$refs.labelBox as any).blur();
    this.$nextTick(async () => await this.createAndUploadFile(file));
  }

  private async createAndUploadFile(file: File) {
    const fileData = new FormData();
    fileData.append('file', file);
    fileData.append('name', file.name);
    fileData.append(this.entity, this.entityId);
    fileData.append(`companyId`, this.$route.params.companyId);
    if (this.selectedLabel) {
      const label = typeof this.selectedLabel === 'string' ?
        this.selectedLabel :
        this.selectedLabel.name;
      if (label?.trim()) {
        fileData.append('label', label);
      }
    }
    this.showCreateDialog = false;
    this.selectedFile = null;
    this.selectedLabel = '';
    this.loading = true;
    try {
      await this.createFileAction(fileData);
      this.$notifySuccessSimplified('MANAGE.FILE_CREATE_MESSAGE.SUCCESS');
    } catch (e: any) {
      this.$notifyErrorSimplified('MANAGE.FILE_CREATE_MESSAGE.ERROR');
    } finally {
      this.loading = false;
    }
  }

  private async createLabel() {
    (this.$refs.labelBox as any).blur();
    this.$nextTick(async () => {
      const name = typeof this.selectedLabel === 'string' ? this.selectedLabel : this.selectedLabel.name;
      if (name.trim()) {
        try {
          await this.createLabelAction({name, companyId: this.$route.params.companyId});
        } catch (e: any) {
          if (e.data.key === 'UNIQUE') {
            this.$notifyErrorSimplified('LABEL.NOTIFICATIONS.UNIQUE');
          } else {
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
          }
        }
      }
    });
  }

  public onRemoveClick(file: DbFile) {
    this.selectedFile = file;
    this.showDeleteDialog = true;
  }

  private async deleteFile() {
    try {
      await this.deleteFileAction(this.selectedFile!.id!);
      this.$notifySuccessSimplified('MANAGE.FILE_DELETE_MESSAGE.SUCCESS');
    } catch {
      this.$notifyErrorSimplified('MANAGE.FILE_DELETE_MESSAGE.ERROR');
    } finally {
      this.closeDeleteDialog();
    }
  }

  private async deleteLabel(item: Label) {
    try {
      await this.deleteLabelAction(item.id!);
      this.$notifySuccessSimplified('MANAGE.LABEL_DELETE_MESSAGE.SUCCESS');
    } catch {
      this.$notifyErrorSimplified('MANAGE.LABEL_DELETE_MESSAGE.ERROR');
    } finally {
      await this.loadLabelsAction(this.$route.params.companyId);
    }
  }
}
