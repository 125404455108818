var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"my-3 pa-0 pt-5",attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex justify-space-between mx-7"},[_c('h4',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.$t('GENERAL.UPLOAD')))]),_c('v-spacer'),_c('search-bar-component',{staticClass:"mr-2",model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}}),_c('v-icon',{on:{"click":function($event){return _vm.onUploadFileIntent()}}},[_vm._v("mdi-upload")])],1),_c('v-divider',{staticClass:"decent-gray my-5"}),_c('table-component',{attrs:{"headers":_vm.tableHeaders,"items":_vm.files,"height":_vm.height,"important-keys":_vm.importantKeys,"show-headers":false,"sort-by":"createdAt","on-row-click":_vm.download,"loading":_vm.loading},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.name)+" "),(item.label !== undefined && item.label !== null && item.label.trim())?_c('v-chip',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(item.label)+" ")]):_vm._e()],1)]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('div',{staticClass:"ml-auto"},[_c('v-btn',{staticClass:"ma-1",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.download(item)}}},[_c('v-icon',[_vm._v(" mdi-download ")])],1),(_vm.$userRoleHandler.hasPermission(_vm.Permission.TIMESHEET_DELETE))?_c('v-btn',{staticClass:"ma-1",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onRemoveClick(item)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1):_vm._e()],1)])]}}]),model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}}),_c('v-dialog',{attrs:{"max-width":"340"},model:{value:(_vm.showCreateDialog),callback:function ($$v) {_vm.showCreateDialog=$$v},expression:"showCreateDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('MANAGE.CREATE_FILE_DIALOG.TITLE')))]),_c('v-card-text',[_c('v-row',{staticClass:"my-2"},[_c('v-file-input',{staticClass:"file-input-border",attrs:{"bg-color":"success","hide-details":""},model:{value:(_vm.selectedFile),callback:function ($$v) {_vm.selectedFile=$$v},expression:"selectedFile"}})],1),_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-combobox',{ref:"labelBox",attrs:{"filled":"","dense":"","hide-details":"","item-value":"name","item-text":"name","items":_vm.labels,"label":_vm.$t('GENERAL.LABEL').toString(),"background-color":"decent-gray"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-n1",attrs:{"icon":""},on:{"click":function($event){return _vm.createLabel()}}},on),[_c('v-icon',[_vm._v(" mdi-plus ")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('SETTINGS.LABEL.CREATE'))+" ")])]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"d-flex ml-auto "},[_c('v-btn',{staticClass:"no-grow no-hover transparent-hover",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteLabel(item)}}},[_c('v-icon',{staticClass:"ml-1 error--text",attrs:{"size":"20"}},[_vm._v(" mdi-minus ")])],1)],1)]}}]),model:{value:(_vm.selectedLabel),callback:function ($$v) {_vm.selectedLabel=$$v},expression:"selectedLabel"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"primary",attrs:{"disabled":_vm.selectedFile === null,"text":""},on:{"click":function($event){return _vm.uploadFile(_vm.selectedFile)}}},[_vm._v(" "+_vm._s(_vm.$t('GENERAL.UPLOAD'))+" ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"340","persistent":""},model:{value:(_vm.showDeleteDialog),callback:function ($$v) {_vm.showDeleteDialog=$$v},expression:"showDeleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('MANAGE.DELETE_FILE_DIALOG.TITLE')))]),_c('v-card-text',[_vm._v(_vm._s(_vm.$t('MANAGE.DELETE_FILE_DIALOG.TEXT', {filename: _vm.fileName}))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error darken-1","text":""},on:{"click":function($event){return _vm.deleteFile()}}},[_vm._v(" "+_vm._s(_vm.$t('GENERAL.DELETE'))+" ")]),_c('v-btn',{attrs:{"color":"darken-1","text":""},on:{"click":function($event){return _vm.closeDeleteDialog()}}},[_vm._v(" "+_vm._s(_vm.$t('GENERAL.CANCEL'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }